import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import { LoggingService } from 'src/core/logging.service';
import { BackgroundEffectsService } from 'src/core/media-device/background-effects/background-effects.service';

import { MediaDeviceState } from '../../media-device/media-device.reducer';
import { MediaDeviceService } from '../../media-device/media-device.service';
import { PlatformService } from '../../platform.service';
import { MediaDeviceFacade } from './../../media-device/media-device.facade';
import { GENERAL_ACTIONS } from './general.actions';
import { GeneralFacade } from './general.facade';

@Injectable()
export class GeneralEffects {
    constructor(
        private actions$: Actions,
        private mediaDeviceService: MediaDeviceService,
        private platformService: PlatformService,
        private router: Router,
        private mediaDeviceFacade: MediaDeviceFacade,
        private loggingService: LoggingService,
        private generalFacade: GeneralFacade
    ) {}

    setWindowActive$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(GENERAL_ACTIONS.setWindowActiveAction),
                withLatestFrom(
                    this.mediaDeviceFacade.mediaDeviceState$,
                    this.generalFacade.getWindowClosed()
                ),
                filter(([, , closed]) => !closed),
                tap(([action, mediaDeviceState]) => {
                    if (
                        this.router.url.startsWith('/home') ||
                        (this.router.url === '/' &&
                            this.platformService.platform === 'electron')
                    ) {
                        this.mediaDeviceService.shouldGetMediaStream =
                            action.active;
                        this.handleStream(action.active, mediaDeviceState);
                    }
                })
            ),
        { dispatch: false }
    );

    setWindowClosed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(GENERAL_ACTIONS.setWindowClosedAction),
                withLatestFrom(this.mediaDeviceFacade.mediaDeviceState$),
                tap(([action, mediaDeviceState]) => {
                    this.mediaDeviceService.shouldGetMediaStream = !action.closed;
                    this.handleStream(!action.closed, mediaDeviceState);
                })
            ),
        { dispatch: false }
    );

    private handleStream(
        shouldBeActive: boolean,
        mediaDeviceState: MediaDeviceState
    ) {
        const streamActive =
            mediaDeviceState.localMediaStream &&
            mediaDeviceState.localMediaStream.active;

        if (shouldBeActive && !streamActive) {
            let delay = 0;
            if (this.platformService.platform === 'ios') {
                delay = 500;
            }
            // this timeout is mentioned in the cordova docs to get around some iOS hanging
            setTimeout(() => {
                this.loggingService.info(
                    'GetUserMedia triggered by window activity/close state change'
                );
                this.mediaDeviceService.getUserMediaNoConstraints();
            }, delay);
        } else if (!shouldBeActive && streamActive) {
            this.mediaDeviceService.releaseUserMedia(
                mediaDeviceState.localMediaStream
            );
            this.mediaDeviceFacade.setLocalMediaStream(null);
        }
    }
}
