import { createAction } from '@ngrx/store';

import { Participant, StageUpdate } from './participant.model';

export const PARTICIPANT_ACTIONS = {
    connectAction: createAction(
        '[Participant] Connect',
        (payload: Participant) => ({ payload })
    ),
    connectSuccessAction: createAction(
        '[Participant] Connect Success',
        (payload: Participant) => ({
            payload,
            useNewObjects: true,
            isBatch: false
        })
    ),
    disconnectSuccessAction: createAction(
        '[Participant] Disconnect Success',
        (payload: string) => ({ payload, useNewObjects: true, isBatch: false })
    ),
    updateAction: createAction(
        '[Participant] Update',
        (payload: Participant) => ({ payload })
    ),
    mutedChangedAction: createAction(
        '[Participant] Muted Changed',
        (payload: Participant) => ({ payload })
    ),
    presentingChangedAction: createAction(
        '[Participant] Presenting Changed',
        (payload: Participant) => ({ payload })
    ),
    protocolChangedAction: createAction(
        '[Participant] Protocol Changed',
        (payload: Participant) => ({ payload })
    ),
    roleChangedAction: createAction(
        '[Participant] Role Changed',
        (payload: Participant) => ({ payload })
    ),
    canMuteChangedAction: createAction(
        '[Participant] Can Mute Changed',
        (payload: Participant) => ({ payload })
    ),
    canRaiseHandChangedAction: createAction(
        '[Participant] Can Raise Hand Changed',
        (payload: Participant) => ({ payload })
    ),
    canSpotlightChangedAction: createAction(
        '[Participant] Can Spotlight Changed',
        (payload: Participant) => ({ payload })
    ),
    canTransferChangedAction: createAction(
        '[Participant] Can Disconnect Changed',
        (payload: Participant) => ({ payload })
    ),
    canDisconnectChangedAction: createAction(
        '[Participant] Can Transfer Changed',
        (payload: Participant) => ({ payload })
    ),
    canControlChangedAction: createAction(
        '[Participant] Can Control Changed',
        (payload: Participant) => ({ payload })
    ),
    serviceTypeChangedAction: createAction(
        '[Participant] Service Type Changed',
        (payload: Participant) => ({ payload })
    ),
    callTypeChangedAction: createAction(
        '[Participant] Call Type Changed',
        (payload: Participant) => ({ payload })
    ),
    spotlightChangedAction: createAction(
        '[Conference] Spotlight Changed',
        (payload: Participant) => ({ payload })
    ),
    buzzChangedAction: createAction(
        '[Conference] Buzz Changed',
        (payload: Participant) => ({ payload })
    ),
    streamingChangedAction: createAction(
        '[Conference] Streaming Changed',
        (payload: Participant) => ({ payload })
    ),
    videoMutedChangedAction: createAction(
        '[Participant] Video Muted Changed',
        (payload: Participant) => ({ payload })
    ),
    videoSilentChangedAction: createAction(
        '[Participant] Video Silent Changed',
        (payload: Participant) => ({ payload })
    ),
    mainVideoDroppedChangedAction: createAction(
        '[Participant] Main Video dropped Changed',
        (payload: Participant) => ({ payload })
    ),
    rxPresChangedAction: createAction(
        '[Participant] Rx Pres Changed',
        (payload: Participant) => ({ payload })
    ),
    fECCSupportedChanged: createAction(
        '[Participant] FECC Supported Changed',
        (payload: Participant) => ({ payload })
    ),
    stageUpdateAction: createAction(
        '[Conference] Stage Update',
        (payload: StageUpdate[]) => ({ payload })
    ),
    connectingAction: createAction(
        '[Participant] Connecting',
        (payload: Participant) => ({
            payload,
            useNewObjects: true,
            isBatch: false
        })
    ),
    connectingFailedAction: createAction(
        '[Participant] Connecting Failed',
        (payload: string) => ({ payload })
    ),
    needsPresentationInMixChangedAction: createAction(
        '[Participant] Needs Presentation In Mix Changed',
        (payload: Participant) => ({ payload })
    ),
    roomIdChangedAction: createAction(
        '[Participant] Room Id Changed',
        (payload: Participant) => ({ payload })
    ),
    clientMutedChangedAction: createAction(
        '[Participant] Client Muted Changed',
        (payload: Participant) => ({ payload })
    )
};

export type ParticipantActions = ReturnType<
    typeof PARTICIPANT_ACTIONS[keyof typeof PARTICIPANT_ACTIONS]
>;
