import { Action, createReducer, on } from '@ngrx/store';
import { PARTICIPANT_ACTIONS } from './participant.actions';
import { Participant } from './participant.model';

export type State = Participant;

const initialState: State = {
    uuid: undefined,
    name: undefined,
    role: undefined,
    uri: undefined,
    startTime: undefined,
    protocol: undefined,
    isExternal: false,
    presenting: false,
    muted: false,
    canControl: false,
    canMute: true,
    canTransfer: true,
    canDisconnect: true,
    serviceType: '',
    callType: '',
    spotlight: undefined,
    buzzTime: undefined,
    isStreaming: false,
    rxPresentation: true,
    isVideoMuted: false,
    isVideoSilent: false,
    isMainVideoDroppedOut: false,
    canRaiseHand: false,
    canSpotlight: true,
    roomId: undefined,
    isIdpAuthenticated: false,
    isClientMuted: false
};

export function reducer(state: State, action: Action) {
    return participantReducer(state, action);
}

const participantReducer = createReducer(
    initialState,
    on(PARTICIPANT_ACTIONS.mutedChangedAction, (state, action) => {
        if (action.payload.uuid === state.uuid) {
            return { ...state, muted: action.payload.muted };
        } else {
            return state;
        }
    }),

    on(PARTICIPANT_ACTIONS.protocolChangedAction, (state, action) => {
        if (action.payload.uuid === state.uuid) {
            return { ...state, protocol: action.payload.protocol };
        } else {
            return state;
        }
    }),

    on(PARTICIPANT_ACTIONS.presentingChangedAction, (state, action) => {
        if (action.payload.uuid === state.uuid) {
            return { ...state, presenting: action.payload.presenting };
        } else {
            return state;
        }
    }),

    on(PARTICIPANT_ACTIONS.roleChangedAction, (state, action) => {
        if (action.payload.uuid === state.uuid) {
            return { ...state, role: action.payload.role };
        } else {
            return state;
        }
    }),

    on(PARTICIPANT_ACTIONS.canControlChangedAction, state => {
        return { ...state, canControl: !state.canControl };
    }),

    on(PARTICIPANT_ACTIONS.canMuteChangedAction, state => {
        return { ...state, canMute: !state.canMute };
    }),

    on(PARTICIPANT_ACTIONS.canRaiseHandChangedAction, state => {
        return { ...state, canRaiseHand: !state.canRaiseHand };
    }),

    on(PARTICIPANT_ACTIONS.canSpotlightChangedAction, state => {
        return { ...state, canSpotlight: !state.canSpotlight };
    }),

    on(PARTICIPANT_ACTIONS.canDisconnectChangedAction, state => {
        return { ...state, canDisconnect: !state.canDisconnect };
    }),

    on(PARTICIPANT_ACTIONS.canTransferChangedAction, state => {
        return { ...state, canTransfer: !state.canTransfer };
    }),

    on(PARTICIPANT_ACTIONS.serviceTypeChangedAction, (state, action) => {
        return { ...state, serviceType: action.payload.serviceType };
    }),

    on(PARTICIPANT_ACTIONS.callTypeChangedAction, (state, action) => {
        return { ...state, callType: action.payload.callType };
    }),

    on(PARTICIPANT_ACTIONS.spotlightChangedAction, (state, action) => {
        return { ...state, spotlight: action.payload.spotlight };
    }),

    on(PARTICIPANT_ACTIONS.videoMutedChangedAction, (state, action) => {
        return { ...state, isVideoMuted: action.payload.isVideoMuted };
    }),

    on(PARTICIPANT_ACTIONS.videoSilentChangedAction, (state, action) => {
        return { ...state, isVideoSilent: action.payload.isVideoSilent };
    }),

    on(PARTICIPANT_ACTIONS.mainVideoDroppedChangedAction, (state, action) => {
        return {
            ...state,
            isMainVideoDroppedOut: action.payload.isMainVideoDroppedOut
        };
    }),

    on(PARTICIPANT_ACTIONS.buzzChangedAction, (state, action) => {
        return { ...state, buzzTime: action.payload.buzzTime };
    }),

    on(PARTICIPANT_ACTIONS.rxPresChangedAction, (state, action) => {
        return { ...state, rxPresentation: action.payload.rxPresentation };
    }),

    on(PARTICIPANT_ACTIONS.fECCSupportedChanged, (state, action) => {
        return { ...state, feccSupported: action.payload.feccSupported };
    }),

    on(
        PARTICIPANT_ACTIONS.needsPresentationInMixChangedAction,
        (state, action) => {
            return {
                ...state,
                needs_presentation_in_mix:
                    action.payload.needs_presentation_in_mix
            };
        }
    ),

    on(PARTICIPANT_ACTIONS.roomIdChangedAction, (state, action) => ({
        ...state,
        roomId: action.payload.roomId
    })),

    on(PARTICIPANT_ACTIONS.clientMutedChangedAction, (state, action) => {
        return { ...state, isClientMuted: action.payload.isClientMuted };
    })
);
